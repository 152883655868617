/* eslint-disable no-param-reassign */
/* eslint-disable global-require */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import secureLs from '@/libs/secureLs'

export default {
  name: 'SettingEkspedisiKomship',
  data() {
    return {
      expedition: [],
      profile: secureLs.getItem('userData'),
      address: [],
      indexFlat: null,
      totalOrder: null,
      loadingFlat: false,
      stateFlat: '',
    }
  },
  created() {
    this.checkAddress()
    this.getExpedition()
  },
  methods: {
    async getExpedition() {
      const setDescription = name => {
        let desc
        if (name === 'IDEXPRESS') {
          desc = ({
            discount: [
              'Berat di atas 500 gram (STD), diskon <b>25%</b>',
              'Berat di bawah 500 gram (IDLite), <b>kurang lebih bayar 1/2-nya</b> ditambah diskon 15%',
              '<b>Cargo (ID Truck)</b> Diskon ongkir 5%, untuk orderan yang beratnya lebih dari 5 kg',
            ],
            recomend: 'Diskon mantap paket ringan <b><1/2 kg</b> dan <b>antar kota besar retur rendah</b>',
          })
          return desc
        } if (name === 'JNE') {
          desc = ({
            discount: [
              'Reguler <b>25%</b>',
              'Belum tersedia layanan OKE dan YES di Komship',
              '<b>Cargo (JNE Trucking)</b> Diskon ongkir 5%, untuk orderan yang beratnya lebih dari 5 kg ',
            ],
            recomend: 'Cabang sangat banyak, retur lebih rendah kirim paket ke <b>daerah terpencil</b>',
          })
          return desc
        } if (name === 'SICEPAT') {
          desc = ({
            discount: [
              'Reguler <b>30%</b>',
              'Cargo (GOKIL) minimal 10kg, <b>mulai dari</b> Rp. 2.500,- / kg ditambah diskon 5%',
            ],
            recomend: 'Diskon mantap paket reguler >1kg  dan <b>antar kota besar</b> retur rendah',
          })
        } if (name === 'SAP') {
          desc = ({
            discount: [
              'Reguler <b>35%</b>',
              '<strong>Cargo (SAP Trucking)</strong> Diskon ongkir 5%, untuk orderan yang beratnya lebih dari 5 kg',
            ],
            recomend: 'Ekspedisi Pelopor COD, Kirim COD <strong>luar jawa</strong> bisa diandalkan, dan CS Supportif',
          })
          return desc
        } if (name === 'J&T') {
          desc = ({
            discount: [
              'Reguler <b>25%</b>',
              'Belum tersedia layanan <strong>J&T Super (Same Day & Next Day)</strong> di Komship.',
            ],
            recomend: 'Memiliki coverage area terbesar dan dapat menjangkau pengiriman Se-Indonesia tanpa pihak ke-3. Operational dan layanan Customer Service 24/7.',
          })
          return desc
        } if (name === 'NINJA') {
          desc = ({
            discount: [
              'Reguler <b>45%</b> 🔥',
              'Belum tersedia layanan <strong>Ninja Sameday</strong> dan <strong> Cargo </strong> di Komship.',
            ],
            recomend: 'Diskon mantap 45% dan pengiriman dilakukan setiap hari (Senin-Minggu). <br> <span class="text-danger">*Tidak termasuk libur nasional</span>',
          })
          return desc
        }
        return desc
      }
      await this.$http_komship.get('/v2/partner/shipment/not-active')
        .then(response => {
          const { data } = response.data
          this.expedition = data.list_shipments.map(items => ({
            id: items.id,
            shipping_name: items.shipping_name,
            image_path: items.image_path,
            is_active: items.is_active === 1,
            flat_update_at: items.flat_update_at,
            desc: setDescription(items.shipping_name),
            visible: false,
            isFlat: items.is_flat === 1 ? 'flat' : 'non-flat',
          }))
          this.totalOrder = data.total_order
        })
        .catch(error => console.error(error))
    },
    collapseExpedition(id) {
      this.$root.$emit('bv::toggle::collapse', id)
    },
    async setExpedition(id) {
      if (this.address.length > 0) {
        const index = this.expedition.findIndex(items => items.id === id)
        if (!this.expedition[index].is_active) {
          this.expedition[index].is_active = true
        } else {
          this.expedition[index].is_active = false
        }
        await this.$http_komship.put(`/v1/partner/shipment/update/${id}`, {
          shipping_name: this.expedition[index].shipping_name,
          is_active: this.expedition[index].is_active ? 1 : 0,
          partner_id: this.profile.partner_detail.id,
          is_komship: 1,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'CheckIcon',
                text: this.expedition[index].is_active ? 'Berhasil mengaktifkan ekspedisi' : 'Berhasil menonaktifkan ekspedisi',
                variant: 'success',
              },
            }, 2000)
            this.$forceUpdate()
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal update setting ekspedisi, silahkan coba lagi',
                variant: 'danger',
              },
            }, 2000)
          })
      } else {
        this.$swal({
          title: '<span class="text-[18px]">Untuk dapat mengaktifkan ekspedisi ini, Kamu harus melengkapi bagian Kelurahan/Kecamatan pada Alamat Pickup</span>',
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: 'Lengkapi Alamat Pickup',
          confirmButtonClass: 'btn btn-primary rounded-lg',
          allowOutsideClick: false,
        }).then(res => {
          if (res.isConfirmed) {
            this.$router.push('/gudangku')
          }
        })
      }
    },
    async checkAddress() {
      await this.$http_komship.get('/v3/address')
        .then(response => {
          const { data } = response.data
          this.address = data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal load data, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    changeFlat(item, state, index) {
      this.indexFlat = index
      if (state === 'flat') {
        this.stateFlat = 'flat'
        this.$refs['komship-flat-modal'].show()
      }
      if (state === 'non-flat') {
        this.stateFlat = 'non-flat'
        this.$refs['komship-flat-modal'].show()
      }
    },
    hideFlat() {
      if (this.stateFlat === 'flat') {
        this.expedition[this.indexFlat].isFlat = 'non-flat'
      } else {
        this.expedition[this.indexFlat].isFlat = 'flat'
      }
      this.$refs['komship-flat-modal'].hide()
    },
    activeFlat() {
      this.loadingFlat = true
      const params = {
        is_flat: this.stateFlat === 'flat' ? 1 : 0,
        shipping_name: 'IDEXPRESS',
      }
      this.$http_komship.post('/v1/partner/shipment/flat', params)
        .then(response => {
          this.loadingFlat = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sukses',
              icon: 'CheckIcon',
              text: `Berhasil mengaktifkan ${this.stateFlat === 'flat' ? 'Komship FLAT' : 'Komship Non-FLAT'}`,
              variant: 'success',
            },
          }, 2000)
          this.getExpedition()
          this.$refs['komship-flat-modal'].hide()
        })
        .catch(err => {
          this.loadingFlat = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, 2000)
        })
    },
    canUpdateFlat(data, state) {
      if (this.totalOrder < 10) {
        if (state === 'non-flat') {
          return false
        }
        if (state === 'flat') {
          return true
        }
      }
      const now = new Date().getTime()
      const flatUpdate = new Date(data.flat_update_at).getTime()
      const distance = flatUpdate - now
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        if (this.totalOrder >= 10) {
          return false
        }
      }
      return true
    },
    isDateExpired(date) {
      const targetDate = new Date(date)
      const currentDate = new Date()

      targetDate.setDate(targetDate.getDate() + 2)
      return currentDate <= targetDate
    },
  },
}
